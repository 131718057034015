export const events = {
  searchResultItemClick: 'saas-search-result-item-click',
  autoSuggestSelect: 'saas-autosuggest-select',
  searchSubmit: 'saas-search-submit',
}

export const createCustomEvent = <T>({ name, data }: { name: string; data?: T }): Event => {
  return new CustomEvent(name, {
    bubbles: true,
    detail: data,
  })
}
