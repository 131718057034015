import { SearchItem } from './searchItem'

export type TabConfig = {
  title: string
  url: string
  tabId: string
}

export type Suggestion = {
  hits: number
  text: string
}

export type Tab = {
  tabId: string
  tabName: string
  index: number
  resultsTotal: number
  results: SearchItem[]
  title: string
  url: string
  suggestion?: Suggestion
}

export const removeAutosuggest = (searchContainer: HTMLDivElement): void => {
  const autoSuggestElement = searchContainer.querySelector<HTMLDivElement>('.cmp-saas__autosuggest')

  autoSuggestElement?.remove()
}
